import React, {useEffect} from 'react';
import styled from "styled-components";
import {Path, PathMap} from "../PathProvider";
import {useNavigate} from "react-router-dom";


export const LoginContentWrapper = styled.div`
    font-family: 'Roboto';
    border: 2px solid #D2D2D2;
    border-radius: 6px;
    max-width: 52em;
    padding: 0em 1em ;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

function AdminLogin() {
    const isReactDevServer = window.location.port.startsWith("300");

    const navigate = useNavigate();
    useEffect(() => {
        if (isReactDevServer) {
            // If this is localhost:3000, we need to skip the SSO flow
            navigate(PathMap.get(Path.AdminLoginResult)!.path!);
        } else {
            // Redirect to the SSO flow
            window.location.href = "/api/adminlogin";
        }
    }, [])

    return(
        <LoginContentWrapper>
            <h3>Admin Account Login</h3>
            <p>Redirecting you to Microsoft Single Sign-On...</p>
        </LoginContentWrapper>
    )
}

export default AdminLogin;