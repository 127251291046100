import React from 'react';
import {useAuth} from "../auth/AuthProvider";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Path, usePath} from "../PathProvider";
import { PrivacyLink } from './Login';

const StyledNav = styled.nav`
    list-style: none;
    margin-right: 55px;
    padding: 0;
    margin-top: 0;
`

const SideNavItem = styled.div<{isActive ?: boolean}>`
    border-bottom: 1px solid #e3e3e3;
    color: #0060a9;
    transition: 0.25s ease-in-out;
    padding: 5px 0;
    cursor: pointer;
    ${props => props.isActive ? 'font-weight: bold;' : ''}
    /*font-family: "foundation_sansbold";*/
    &:hover {
      color: #000;
      text-decoration: underline;
    }
`
const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
    &:visited {
      color: #0060a9;
    }
`

const StyledNavExternalLink = styled.li`
    border-bottom: 1px solid #e3e3e3;
    color: #0060a9;
    transition: 0.25s ease-in-out;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
`
type NavItemProps = {
    to : string;
    text : string;
}

export function PrivacyNavItem() {
    return (
        <StyledNavExternalLink>
            <PrivacyLink/>
        </StyledNavExternalLink>
    )
}

function SideNavLink(props : NavItemProps) {
    return (
        <StyledNavLink to={props.to}>
            {({ isActive }) => (
                <SideNavItem isActive={isActive}>
                    {props.text}
                </SideNavItem>
            )}
        </StyledNavLink>
    )
}

function SideNav() {
    const auth = useAuth();
    const path = usePath();

    return(
        <StyledNav>
            {/*Super admin only links*/}
            {
                auth.user?.superAdmin &&
                <React.Fragment>
                    <SideNavLink to={path.get(Path.ThirdPartyTable)} text={'Third Party'} />
                </React.Fragment>
            }
            <SideNavLink to={path.get(Path.SUMR)} text={'SU-MR'} />
            {/*Admin only links*/}
            {
                (auth.user?.admin) &&
                <React.Fragment>
                    <SideNavLink to={path.get(Path.UserTable)} text={'Manage Accounts'} />
                    <SideNavLink to={path.get(Path.AttestPage)} text={'Attest Access'} />
                </React.Fragment>
            }
            <SideNavLink to={path.get(Path.AuditLog)} text={'Activity Log'} />
            <SideNavLink to={path.get(Path.ResetPassword)} text={'Reset Password'} />
            <br />
            <br />
            <PrivacyNavItem/>
            <SideNavItem onClick={() => auth.logout()}>Logout</SideNavItem>
        </StyledNav>
    )
}

export default SideNav;