import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAuth} from "../auth/AuthProvider";
import AuthResponse from "../models/AuthResponse";
import {useAxios} from "../AxiosProvider";
import {AxiosError, AxiosResponse} from "axios";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {Input} from "./form/FormElements";
import {useMessageService} from "../hooks/useMessageService";
import {Form, Label, PrimaryButton} from "../styledcomponents/FormStyledComponents";
import {useNavigate} from "react-router-dom";
import {ResponseCode} from "../enums/ResponseCode";
import {Path, usePath} from "../PathProvider";
import {PageTitle} from '../styledcomponents/MiscStyledComponents';
import ErrorUtils from "../utils/errorUtils";

const LoginFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5rem;
`

const LoginWrapper = styled.div`
    display: grid;
    width: 100%;
    gap: 3rem;
    grid-auto-flow: column;
    @media (max-width: 768px) {
      grid-auto-flow: row;
    }
`

const LoginContent = styled.div.attrs({
    tabIndex: 0
})`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 15px;
`

const LoginWidget = styled.div`
    font-family: 'Roboto';
    display: flex;
    border: 2px solid #D2D2D2;
    border-radius: 6px;
`

const AccountLogInwidget = styled.div`
    clear: both;
    width: 50%;
    padding: 30px;
`

const AccountLogInMain = styled.div`
    display: flex;
`

const AccountLogIn = styled.div.attrs({
    tabIndex: 0, // Default type attribute for all Button components
})`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    color: #00477D;
`
const LogInFormwidget = styled.div`
    clear: both;
    width: 50%;
    padding: 35px;
    background: #E6F2FA;
`

const AccountLogInContent = styled.div`
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 10px;
`

const RequestAccess = styled.div`
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #1A6EAD;
    padding-top: 20px;
    text-decoration: none;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: #0060A9;
`

export const PrivacyLink = () => <StyledLink href={"https://www.firstenergycorp.com/corporate/privacy-notice.html"} target={"_blank"} style={{display : 'contents'}}>Privacy Notice</StyledLink>


type LoginFormData = {
    username: string,
    password: string,
};

function LoginForm() {

    const LINK_HOST = window.location.origin.match(/localhost|thirdparty.dev|thirdparty.qa/g) ? "https://www-qa-b.firstenergycorp.com" : "https://firstenergycorp.com"

    const REQUEST_ACCESS_URL = LINK_HOST + `${process.env.REACT_APP_REQUEST_ACCESS_URL}`;
    const USER_GUIDE_URL = LINK_HOST + `${process.env.REACT_APP_USER_GUIDE_URL}`;
    const CONTACT_US_URL = LINK_HOST + `${process.env.REACT_APP_CONTACT_US_URL}`;
    // Hooks
    const auth = useAuth();
    const navigate = useNavigate();
    const axios = useAxios();
    const messageService = useMessageService();
    const path = usePath();

    // useForm hooks
    const methods = useForm<LoginFormData>({});
    const {handleSubmit, formState : {isSubmitting, isDirty}} = methods;

    // Form state handlers
    const [loginDisabled, setLoginDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setLoginDisabled(isSubmitting || !isDirty);
    }, [isSubmitting, isDirty]);

    // Functions
    const onSubmit : SubmitHandler<LoginFormData> = async (data : LoginFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        await axios?.openApi.post(
            '/api/login',
            { username: data.username, password: data.password }
        ).then((response : AxiosResponse) => {
            const authResponse : AuthResponse = response.data;

            // if user is a super admin, let them just log in, we will never need them to acknowledge or attest to anything
            if (authResponse.superAdmin) {
                auth.login(authResponse);
                return;
            }

            // if this account needs to attest, then take them to the attest page,
            // if they don't then take them to the acknowledgement page
            // they will get logged in on the front end from those pages
            // we don't want to log them in on the front end here because we don't want them to be able to access anything until they attest
            if (authResponse.needsAttest) {
                navigate(path.get(Path.AttestPage), { state : { authResponse : authResponse }});
            } else {
                navigate(path.get(Path.AcknowledgementPage), { state : { authResponse : authResponse }});
            }
        }).catch((err : AxiosError) => {
            console.error(err);

            let errorResponse : AxiosResponse | undefined = err.response;

            switch (errorResponse?.status) {
                case 400 :
                    let response = Object.values(errorResponse?.data).join("\n");
                    messageService.error(response);
                    break;
                case 409 :
                    switch (errorResponse?.data.message) {
                        case ResponseCode.ForcePasswordReset :
                            navigate(path.get(Path.ResetPassword), { state : { warningMessage : 'Password expired. Please change your password.' }});
                            break;
                        case ResponseCode.NeedsAttest:
                            messageService.error('Attestation needed. Please contact your FE Administrator.');
                            break;
                        case ResponseCode.DuplicateUserSessions:
                            // the api will consider a session active if it is younger than 15 minutes
                            messageService.error('Another active session was found. Please wait 15 minutes or contact your Third Party or FE Administrator.');
                            break;
                        default:
                            messageService.error('The information provided does not match our records. Contact your Third Party Administrator or FE Administrator to reset your online account.');
                    }
                    break;
                default :
                    messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }
        });
    };

    return(
        <LoginFormWrapper>
            <FormProvider {...methods} >
                <PageTitle>Third Party Data Access</PageTitle>
                <LoginContent>
                    Utilities have access to vast amounts of data on their customers’ energy use and behaviors. Customers can choose to allow third parties to have access to this data. As authorized by a customer, Third Parties, currently limited to Curtailment Service Providers and Conservation Service Providers, can access Individual Customer Data and request access to Aggregated and Anonymous Customer Data.
                </LoginContent>
                <LoginContent>
                    If you don’t have an account, you can apply for access by following the instructions <StyledLink href={REQUEST_ACCESS_URL} target={"_blank"} >here</StyledLink>.
                </LoginContent>

                <LoginWidget>
                    <AccountLogInwidget>
                        <AccountLogInMain>
                            <div tabIndex={0}>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.6" d="M12.1255 2.40137C6.77785 2.40137 2.42456 6.70937 2.42456 12.0014C2.42456 14.1854 3.17638 16.1894 4.41325 17.7974C6.14729 15.7094 10.3551 15.0014 12.1255 15.0014C13.8959 15.0014 18.1037 15.7094 19.8377 17.7974C21.0746 16.1894 21.8264 14.1854 21.8264 12.0014C21.8264 6.70937 17.4731 2.40137 12.1255 2.40137ZM12.1255 13.2014C9.77302 13.2014 7.88134 11.3294 7.88134 9.00137C7.88134 6.67337 9.77302 4.80137 12.1255 4.80137C14.478 4.80137 16.3697 6.67337 16.3697 9.00137C16.3697 11.3294 14.478 13.2014 12.1255 13.2014Z" fill="#AED3ED" />
                                    <path d="M12.1262 0C5.43252 0 0 5.376 0 12C0 18.624 5.43252 24 12.1262 24C18.8198 24 24.2523 18.624 24.2523 12C24.2523 5.376 18.8198 0 12.1262 0ZM6.14797 19.536C6.66939 18.456 9.84645 17.4 12.1262 17.4C14.4059 17.4 17.5951 18.456 18.1044 19.536C16.4552 20.832 14.3816 21.6 12.1262 21.6C9.8707 21.6 7.79713 20.832 6.14797 19.536ZM19.8384 17.796C18.1044 15.708 13.8966 15 12.1262 15C10.3557 15 6.14797 15.708 4.41393 17.796C3.17706 16.188 2.42523 14.184 2.42523 12C2.42523 6.708 6.77853 2.4 12.1262 2.4C17.4738 2.4 21.8271 6.708 21.8271 12C21.8271 14.184 21.0753 16.188 19.8384 17.796ZM12.1262 4.8C9.77369 4.8 7.88201 6.672 7.88201 9C7.88201 11.328 9.77369 13.2 12.1262 13.2C14.4786 13.2 16.3703 11.328 16.3703 9C16.3703 6.672 14.4786 4.8 12.1262 4.8ZM12.1262 10.8C11.1197 10.8 10.3072 9.996 10.3072 9C10.3072 8.004 11.1197 7.2 12.1262 7.2C13.1326 7.2 13.9451 8.004 13.9451 9C13.9451 9.996 13.1326 10.8 12.1262 10.8Z" fill="#0060A9" />
                                </svg>
                            </div>
                            <AccountLogIn>
                                Account Log In
                            </AccountLogIn>
                        </AccountLogInMain>
                        <AccountLogInContent>
                            Log in to your account to establish new service
                        </AccountLogInContent>
                        <AccountLogInContent>
                            Contact your organization’s administrator to reset your password. If you are the administrator for your organization, <StyledLink href={CONTACT_US_URL} target={"_blank"}>contact us</StyledLink> to have your password reset.
                        </AccountLogInContent>
                        <RequestAccess>
                            <StyledLink href={REQUEST_ACCESS_URL} target={"_blank"}>Request Access</StyledLink>
                        </RequestAccess>
                        <RequestAccess>
                            <StyledLink href={USER_GUIDE_URL} target={"_blank"}>User Guide</StyledLink>
                        </RequestAccess>
                        <p><br/><PrivacyLink/></p>
                    </AccountLogInwidget>
                    <LogInFormwidget>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Label htmlFor={"username"} >Username</Label>
                            <Input name={"username"} />
                            <Label htmlFor={"password"} >Password</Label>
                            <Input type="password" name={"password"} />
                            <PrimaryButton type={"submit"} disabled={loginDisabled} float='left'>
                                {isSubmitting ? 'Logging in...' : 'LOG IN'}</PrimaryButton>
                        </Form>
                    </LogInFormwidget>
                </LoginWidget>
            </FormProvider>
        </LoginFormWrapper>
    )
}

function Login() {
    const auth = useAuth();
    if (auth.user) { auth.logout(); }

    return(
        <LoginWrapper>
            <LoginForm/>
        </LoginWrapper>
    )
}

export default Login;