import {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAuth} from "../auth/AuthProvider";
import AuthResponse from "../models/AuthResponse";
import {useAxios} from "../AxiosProvider";
import {AxiosError, AxiosResponse} from "axios";
import { LoginContentWrapper } from './AdminLogin';
import { StyledLink } from './Login';

const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5em;
    margin: 1.5em;
`

/** This is where we end up after SSO. */
function AdminLoginResult() {
    // Hooks
    const auth = useAuth(); 
    const axios = useAxios();
    const [errorMessage, setErrorMessage] = useState<string|null>(null)

    useEffect(() => {
        axios?.openApi.get(
            '/api/adminlogin/fetchAuth',
        ).then((response : AxiosResponse) => {
            if (response.data.error) {
                // there is an error message from auth
                let error: string = response.data.error;
                if (response.data.error.includes('required permissions')) {
                    error += ' Contact your program administrator to grant access or log in with a different user by clicking Switch User below.'
                }
                setErrorMessage("Error authorizing account: " + error);
                return;
            }
            // successful admin login
            const authResponse : AuthResponse = response.data;
            auth.login(authResponse);
        }).catch((err : AxiosError) => {
            console.error(err);
            setErrorMessage("We were unable to authorize your account. If this issue persists, contact your program administrator.")
        });
    }, [])

    return(
        <LoginContentWrapper>
            <h3>Admin Account Login</h3>
            {errorMessage ? 
                <div>
                    <p>{errorMessage}</p> 
                    <LinkContainer>
                        <div><StyledLink href="/adminLogin">Retry Login</StyledLink></div>
                        <div><StyledLink href="/logout">Switch User (SSO Sign Out)</StyledLink></div>
                    </LinkContainer>
                    
                </div>
             : <p>Authorizing...</p> 
            }       
        </LoginContentWrapper>
    )
}

export default AdminLoginResult;